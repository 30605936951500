$primary-color: #0a0099;
$primary-lighter-color: #B4B9D0;
$primary-light-color: #57b4cc;
$primary-dark-color: #040048;
$primary-darkness-color: #030024;
$primary-text-color: #fff;

$incident-primary-color: #ab0000;
$incident-primary-lighter-color: #ffc1b2;
$incident-primary-light-color: #ff3a3a;
$incident-primary-dark-color: #4e0000;
$incident-primary-darkness-color: #170000;
$incident-primary-text-color: #fff;

$audit-primary-color: #aaabab;
$audit-primary-lighter-color: #f2f3f3;
$audit-primary-light-color: #cecfcf;
$audit-primary-dark-color: #5a5a5a;
$audit-primary-darkness-color: #232424;
$audit-primary-text-color: #fff;

$task-primary-color: #fcc100;
$task-primary-lighter-color: #fff4d5;
$task-primary-light-color: #cbbb57;
$task-primary-dark-color: #816200;
$task-primary-darkness-color: #433300;
$task-primary-text-color: #fff;

$msbs-primary-color: #ab0099;
$msbs-primary-lighter-color: #ffdfd8;
$msbs-primary-light-color: #cc7aa9;
$msbs-primary-dark-color: #490041;
$msbs-primary-darkness-color: #280023;
$msbs-primary-text-color: #fff;

$bad-primary-color: #30D630;
$bad-primary-lighter-color: #addaad;
$bad-primary-light-color: #71a571;
$bad-primary-dark-color: #176717;
$bad-primary-darkness-color: #093509;
$bad-primary-text-color: #fff;

$orz-primary-color: #57C1DC;
$orz-primary-lighter-color: #97cfdd;
$orz-primary-light-color: #6aa5b4;
$orz-primary-dark-color: #2C616E;
$orz-primary-darkness-color: #1c3d46;
$orz-primary-text-color: #fff;

//btn
$btn-default-background-image:                  linear-gradient(to bottom, #fff 0%, #f5f5f5 100%);
$btn-default-color-hover:                       $primary-text-color;
$btn-default-border-color-hover:                $primary-dark-color;
$btn-default-background-color-hover:            $primary-dark-color;
$btn-default-background-image-hover:            linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);

//btn-primary
$btn-primary-border-color:                      $primary-color;
$btn-primary-background-image:                  linear-gradient(to bottom, $primary-color 0%, $primary-color 100%);
$btn-primary-color-hover:                       $primary-text-color;
$btn-primary-border-color-hover:                $primary-dark-color;
$btn-primary-background-color-hover:            $primary-dark-color;
$btn-primary-background-image-hover:            linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);

//dropdown-menu
$dropdown-menu-li-a-color-hover:                $primary-text-color;
$dropdown-menu-li-a-background-color-hover:     linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);
$dropdown-menu-li-a-background-image-hover:     $primary-dark-color;

//panel
$panel-default-border-color:                    $primary-color;
$panel-default-border-top-color:                $primary-lighter-color;

$panel-default-panel-heading-border-color:      $primary-color;
$panel-default-panel-heading-background-image:  linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);
$panel-default-panel-heading-color:             $primary-text-color;

//nav
$navbar-toggle-color: $primary-lighter-color;
$navbar-toggle-collapsed-color: $primary-text-color;

@import "../../../node_modules/bootstrap-css-only/css/bootstrap.css";
@import "../../../node_modules/bootstrap-css-only/css/bootstrap-theme.css";

@mixin btn($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color) {
  &.active {
    background-color: $primary-lighter-color;
 }    //color: $primary-text-color

  &:hover,&:focus,&:active,&:active:hover,&:active:focus {
    color: $primary-text-color;
    border-color: $primary-dark-color;
    background-color: $primary-dark-color;
    background-image: linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);
    text-shadow: none; } }

@mixin btn-primary($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color) {
  border-color: $primary-color;
  background-image: linear-gradient(to bottom, $primary-color 0%, $primary-color 100%);

  &:hover,&:focus,&:active,&:active:hover,&:active:focus {
    color: $primary-text-color;
    border-color: $primary-dark-color;
    background-color: $primary-dark-color;
    background-image: linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%); }
  &.active {
    background-color: {}
    color: $primary-text-color;
    text-shadow: none; } }

.btn {
  &-default {
    background-image: $btn-default-background-image;
    &:hover,&:focus,&:active,&:active:hover,&:active:focus {
      color: $btn-default-color-hover;
      border-color: $btn-default-border-color-hover;
      background-color: $btn-default-background-color-hover;
      background-image: $btn-default-background-image-hover;
      text-shadow: none; }
    &.active:hover,&.active:focus {
      color: $btn-default-color-hover;
      background-color: $btn-default-background-color-hover;
      border-color: $btn-default-border-color-hover; }


    @include btn($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color);

    .incident & {
      @include btn($incident-primary-color,$incident-primary-lighter-color,$incident-primary-light-color,$incident-primary-dark-color,$incident-primary-darkness-color,$incident-primary-text-color); }
    .audit & {
      @include btn($audit-primary-color,$audit-primary-lighter-color,$audit-primary-light-color,$audit-primary-dark-color,$audit-primary-darkness-color,$audit-primary-text-color); }
    .task & {
      @include btn($task-primary-color,$task-primary-lighter-color,$task-primary-light-color,$task-primary-dark-color,$task-primary-darkness-color,$task-primary-text-color); }

    .msbs & {
      @include btn($msbs-primary-color,$msbs-primary-lighter-color,$msbs-primary-light-color,$msbs-primary-dark-color,$msbs-primary-darkness-color,$msbs-primary-text-color); }

    .bad & {
      @include btn($bad-primary-color,$bad-primary-lighter-color,$bad-primary-light-color,$bad-primary-dark-color,$bad-primary-darkness-color,$bad-primary-text-color); }

    .orz & {
      @include btn($orz-primary-color,$orz-primary-lighter-color,$orz-primary-light-color,$orz-primary-dark-color,$orz-primary-darkness-color,$orz-primary-text-color); } }

  &-primary {
    @include btn-primary($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color);

    .incident & {
      @include btn-primary($incident-primary-color,$incident-primary-lighter-color,$incident-primary-light-color,$incident-primary-dark-color,$incident-primary-darkness-color,$incident-primary-text-color); }
    .audit & {
      @include btn-primary($audit-primary-color,$audit-primary-lighter-color,$audit-primary-light-color,$audit-primary-dark-color,$audit-primary-darkness-color,$audit-primary-text-color); }
    .task & {
      @include btn-primary($task-primary-color,$task-primary-lighter-color,$task-primary-light-color,$task-primary-dark-color,$task-primary-darkness-color,$task-primary-text-color); }

    .msbs & {
      @include btn-primary($msbs-primary-color,$msbs-primary-lighter-color,$msbs-primary-light-color,$msbs-primary-dark-color,$msbs-primary-darkness-color,$msbs-primary-text-color); }

    .bad & {
      @include btn-primary($bad-primary-color,$bad-primary-lighter-color,$bad-primary-light-color,$bad-primary-dark-color,$bad-primary-darkness-color,$bad-primary-text-color); }

    .orz & {
      @include btn-primary($orz-primary-color,$orz-primary-lighter-color,$orz-primary-light-color,$orz-primary-dark-color,$orz-primary-darkness-color,$orz-primary-text-color); } }

  &-quick-access {
    @extend .btn-default;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
    height: 100%;
    min-height: 100%;
    vertical-align: middle;
    @media (max-width: 767px) {
      width: 100%; } }

  &-list-delete-button {
    padding: 2px 4px; }

  &-MultiSelect {
    text-shadow: none; }

  &-disable-filter {
    border-color: $btn-primary-border-color;
    background-image: $btn-primary-background-image;
    &:hover,&:focus,&:active,&:active:hover,&:active:focus {
      color: $btn-primary-color-hover;
      border-color: $btn-primary-border-color-hover;
      background-color: $btn-primary-background-color-hover;
      background-image: $btn-primary-background-image-hover;
      text-shadow: none; } } }

@mixin dropdown-menu-a($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color) {
  &:hover {
    color: $primary-text-color;
    background-image: linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);
    background-color: $primary-dark-color; } }
@mixin progress-dropdown-menu($background) {
  & > a {
    background-color: $background; }

  & > li {
    & > a {
      white-space: normal;
      @include dropdown-menu-a($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color);
      .incident & {
        @include dropdown-menu-a($incident-primary-color,$incident-primary-lighter-color,$incident-primary-light-color,$incident-primary-dark-color,$incident-primary-darkness-color,$incident-primary-text-color); }
      .audit & {
        @include dropdown-menu-a($audit-primary-color,$audit-primary-lighter-color,$audit-primary-light-color,$audit-primary-dark-color,$audit-primary-darkness-color,$audit-primary-text-color); }
      .task & {
        @include dropdown-menu-a($task-primary-color,$task-primary-lighter-color,$task-primary-light-color,$task-primary-dark-color,$task-primary-darkness-color,$task-primary-text-color); }
      .msbs & {
        @include dropdown-menu-a($msbs-primary-color,$msbs-primary-lighter-color,$msbs-primary-light-color,$msbs-primary-dark-color,$msbs-primary-darkness-color,$msbs-primary-text-color); }
      .bad & {
        @include dropdown-menu-a($bad-primary-color,$bad-primary-lighter-color,$bad-primary-light-color,$bad-primary-dark-color,$bad-primary-darkness-color,$bad-primary-text-color); }
      .orz & {
        @include dropdown-menu-a($orz-primary-color,$orz-primary-lighter-color,$orz-primary-light-color,$orz-primary-dark-color,$orz-primary-darkness-color,$orz-primary-text-color); } }

    &.dictionary_progress {
      &.open {
        @include progress-dropdown-menu(#aae2ff); }
      &.verification {
        @include progress-dropdown-menu(#ff890e); }
      &.closed {
        @include progress-dropdown-menu(#b2ff91); }
      &.draff {
        @include progress-dropdown-menu(#ddd); } } } }

.navbar.navbar-default {
  border: none;
  border-radius: 0; }

.nav {
  &.navbar-nav {
    float: none; }
  & > li {
    float: none; }
  .open {
    & > a {
      &,&:hover,&:active,&:focus {
        background: none;
        text-decoration: none;
        color: $primary-text-color; } } } }
.nav > li {
  display: flex;
  align-items: center;
  height: 66px;
  @media screen and (max-width: 767px) {
    height: auto; } }

@mixin navbar($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color) {
  .navbar-brand {
    color: $primary-text-color;
    &:hover,&:focus {
      color: $primary-lighter-color; } }

  .navbar-nav {
    & > li {
      & > a {
        padding-top: 8px;
        padding-bottom: 8px;
        color: $primary-text-color;
        &:hover {
          color: $primary-lighter-color; }

        & > div {
          &.active {
            color: $primary-lighter-color; } } } } } }


.navbar {
  .navbar-brand {
    height: auto;
    padding: 10px 15px 10px; }
  @include navbar($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color);

  .incident & {
    @include navbar($incident-primary-color,$incident-primary-lighter-color,$incident-primary-light-color,$incident-primary-dark-color,$incident-primary-darkness-color,$incident-primary-text-color); }
  .audit & {
    @include navbar($audit-primary-color,$audit-primary-lighter-color,$audit-primary-light-color,$audit-primary-dark-color,$audit-primary-darkness-color,$audit-primary-text-color); }
  .task & {
    @include navbar($task-primary-color,$task-primary-lighter-color,$task-primary-light-color,$task-primary-dark-color,$task-primary-darkness-color,$task-primary-text-color); }
  .msbs & {
    @include navbar($msbs-primary-color,$msbs-primary-lighter-color,$msbs-primary-light-color,$msbs-primary-dark-color,$msbs-primary-darkness-color,$msbs-primary-text-color); }
  .bad & {
    @include navbar($bad-primary-color,$bad-primary-lighter-color,$bad-primary-light-color,$bad-primary-dark-color,$bad-primary-darkness-color,$bad-primary-text-color); }
  .orz & {
    @include navbar($orz-primary-color,$orz-primary-lighter-color,$orz-primary-light-color,$orz-primary-dark-color,$orz-primary-darkness-color,$orz-primary-text-color); } }



@mixin navbar-default($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color) {
  background-color: $primary-color;
  background-image: linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 60%,$primary-darkness-color 80%, #000);
  .navbar-nav {
    & > li {
      & > a {
        &:focus {
          color: $primary-lighter-color; } } }

    & > .open {
      & > a {
        color: $primary-lighter-color;
        &:hover {
          color: $primary-lighter-color; } } } }

  .navbar-toggle {
    background-color: $primary-text-color;
    &:focus {
      background-color: $primary-text-color; }

    .icon-bar {
      background-color: $primary-lighter-color; }

    &.collapsed {
      border-color: $primary-text-color;
      background-color: inherit;
      .icon-bar {
        background-color: $navbar-toggle-collapsed-color; } } } }


.navbar-default {
  @include navbar-default($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color);
  .audit & {
    @include navbar-default($audit-primary-color,$audit-primary-lighter-color,$audit-primary-light-color,$audit-primary-dark-color,$audit-primary-darkness-color,$audit-primary-text-color); }
  .incident & {
    @include navbar-default($incident-primary-color,$incident-primary-lighter-color,$incident-primary-light-color,$incident-primary-dark-color,$incident-primary-darkness-color,$incident-primary-text-color); }
  .task & {
    @include navbar-default($task-primary-color,$task-primary-lighter-color,$task-primary-light-color,$task-primary-dark-color,$task-primary-darkness-color,$task-primary-text-color); }
  .msbs & {
    @include navbar-default($msbs-primary-color,$msbs-primary-lighter-color,$msbs-primary-light-color,$msbs-primary-dark-color,$msbs-primary-darkness-color,$msbs-primary-text-color); }
  .bad & {
    @include navbar-default($bad-primary-color,$bad-primary-lighter-color,$bad-primary-light-color,$bad-primary-dark-color,$bad-primary-darkness-color,$bad-primary-text-color); }
  .orz & {
    @include navbar-default($orz-primary-color,$orz-primary-lighter-color,$orz-primary-light-color,$orz-primary-dark-color,$orz-primary-darkness-color,$orz-primary-text-color); } }





.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  @media (max-width: 767px) {
    color: $primary-text-color; } }

@mixin panelBuilder($className) {
  .panel-#{$className} {
    border-color: $panel-default-border-color;
    border-top-color: $panel-default-border-top-color;
    & > .panel-heading {
      border-color: $panel-default-panel-heading-border-color;
      background-image: $panel-default-panel-heading-background-image;
      color: $panel-default-panel-heading-color; } } }

@mixin panel-heading($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color) {
  border-color: $primary-color;
  background-image: linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 60%, #000 100%);
  color: $primary-text-color; }

.panel {
  max-width: 100%;
  &-default {
    border-color: $panel-default-border-color;
    border-top-color: $panel-default-border-top-color;
    & > .panel-heading {
      @include panel-heading($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color); }
    & > .panel-body {
      position: relative; } }
  @include panelBuilder("Doom"); }



.table {
  &-notification {
    & > tbody > tr {
      &.first {
        border-top: 1px solid #ddd; }
      & > td {
        border: none;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd; } } } }

@mixin open-droptoggle($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color) {
  box-shadow: none;
  &:focus,&:hover {
    color: $primary-text-color;
    background-color: $primary-dark-color;
    border-color: $primary-dark-color; } }

.open > div > .dropdown-toggle.btn-default {
  // font-size: 25px
  @include open-droptoggle($primary-color,$primary-lighter-color,$primary-light-color,$primary-dark-color,$primary-darkness-color,$primary-text-color);
  .incident & {
    @include open-droptoggle($incident-primary-color,$incident-primary-lighter-color,$incident-primary-light-color,$incident-primary-dark-color,$incident-primary-darkness-color,$incident-primary-text-color); }
  .audit & {
    @include open-droptoggle($audit-primary-color,$audit-primary-lighter-color,$audit-primary-light-color,$audit-primary-dark-color,$audit-primary-darkness-color,$audit-primary-text-color); }
  .task & {
    @include open-droptoggle($task-primary-color,$task-primary-lighter-color,$task-primary-light-color,$task-primary-dark-color,$task-primary-darkness-color,$task-primary-text-color); }
  .msbs & {
    @include open-droptoggle($msbs-primary-color,$msbs-primary-lighter-color,$msbs-primary-light-color,$msbs-primary-dark-color,$msbs-primary-darkness-color,$msbs-primary-text-color); }
  .bad & {
    @include btn($bad-primary-color,$bad-primary-lighter-color,$bad-primary-light-color,$bad-primary-dark-color,$bad-primary-darkness-color,$bad-primary-text-color); }
  .orz & {
    @include btn($orz-primary-color,$orz-primary-lighter-color,$orz-primary-light-color,$orz-primary-dark-color,$orz-primary-darkness-color,$orz-primary-text-color); } }

.navbar-collapse {
  @media (max-width: 767px) {
    padding-top: 5px;
    padding-bottom: 5px; } }

.mobile {
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    &.collapse {
      border-top: 1px #fff solid;
      display: none !important;
      @media (min-width: 768px) {
        margin: 0 -23px; }
      &.in {
        display: block !important; } } }

  .container {
    & > .navbar-header {
      margin-left: -15px;
      margin-right: -15px; }
    & > .navbar-collapse {} }

  .collapsing {
    border-top: 1px #fff solid;
    @media (min-width: 768px) {
      margin: 0 -23px; } }

  .navbar-nav {
    float: none;
    margin-right: -15px !important; }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: auto;
    background-color: transparent;
    border: 0;
    box-shadow: none; }

  .nav > li {
    height: auto; }

  .navbar-right {
    float: none !important; }

  .dropdown-menu > li > a {
    color: $primary-text-color; } }
