.init{
    width:100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.init img {
    max-width: 300px;
    width:100%;
    padding: 20px;
}

.loading-text {
    padding: 8px 0;
}