.ehs-menu {
  display: block;
  width: 100%;
  max-width: 50px;
  height: 50px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  &-dashboard {
    background-image: url("../icons/dashboard.svg");
    &:hover {
      background-image: url("../icons/dashboard_1.svg"); } }
  &-incidents {
    background-image: url("../icons/incidents.svg");
    &:hover {
      background-image: url("../icons/incidents_1.svg"); } }
  &-audit {
    background-image: url("../icons/audit.svg");
    &:hover {
      background-image: url("../icons/audit_1.svg"); } }

  &-action-items {
    background-image: url("../icons/action_items.svg");
    &:hover {
      background-image: url("../icons/action items_1.svg"); } }

  &-msds {
    background-image: url("../icons/msds.svg");
    &:hover {
      background-image: url("../icons/msds_1.svg"); } }
  &-localizations {
    background-image: url("../icons/locations.svg");
    &:hover {
      background-image: url("../icons/locations_1.svg"); } }
  &-notices {
    background-image: url("../icons/notices.svg");
    &:hover {
      background-image: url("../icons/notices_1.svg"); } }
  &-responsibilities {
    background-image: url("../icons/responsibilities.svg");
    &:hover {
      background-image: url("../icons/responsibilities_1.svg"); } }
  &-roles {
    background-image: url("../icons/roles.svg");
    &:hover {
      background-image: url("../icons/roles_1.svg"); } }
  &-users {
    background-image: url("../icons/users.svg");
    &:hover {
      background-image: url("../icons/users_1.svg"); } }
  &-help {
    background-image: url("../icons/help.svg");
    &:hover {
      background-image: url("../icons/help_1.svg"); } }
  &-logout {
    background-image: url("../icons/logout.svg");
    &:hover {
      background-image: url("../icons/logout_1.svg"); } } }
