@import "./bootstrap-override";

.tooltip-calendar-hover {
  white-space: nowrap;
  position: absolute;
  left: 0;
  transform: translateX(-50%);
  padding: 7px 15px;
  box-sizing: border-box; }


.accordion__title {
  cursor: pointer; }

.accordion__item {}


.accordion__body {
  &--hidden {
    display: {} } }

input {
  &:disabled {
    background-color: rgba(239, 239, 239, 0.3); } }
textarea {
  &:disabled {
    background-color: rgba(239, 239, 239, 0.3); } }

