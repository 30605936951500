.text-editor {
  ol {
    &.normal {
      font-size: 14px; }
    &.large {
      font-size: 24px; }
    &.xlarge {
      font-size: 36px; }
    &.xxlarge {
      font-size: 42px; } } }
